import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [team1, setTeam1] = useState([]);
  const [team2, setTeam2] = useState([]);
  const [queue, setQueue] = useState([]);
  const [newPlayerName, setNewPlayerName] = useState('');

  useEffect(() => {
    axios.get('/wp-json/basketball/v1/players')
      .then(response => {
        setAvailablePlayers(response.data);
      })
      .catch(error => {
        console.error('Error fetching players:', error);
      });
  }, []);

  const addPlayerToQueue = (player) => {
    setQueue([...queue, player]);
    setAvailablePlayers(availablePlayers.filter(p => p.ID !== player.ID));
  };

  const removePlayerFromQueue = (player) => {
    setAvailablePlayers([...availablePlayers, player]);
    setQueue(queue.filter(p => p.ID !== player.ID));
  };

  const movePlayerUp = (list, setList, player) => {
    const index = list.findIndex(p => p.ID === player.ID);
    if (index > 0) {
      const newList = [...list];
      [newList[index - 1], newList[index]] = [newList[index], newList[index - 1]];
      setList(newList);
    }
  };

  const movePlayerDown = (list, setList, player) => {
    const index = list.findIndex(p => p.ID === player.ID);
    if (index < list.length - 1) {
      const newList = [...list];
      [newList[index + 1], newList[index]] = [newList[index], newList[index + 1]];
      setList(newList);
    }
  };

  const formTeams = () => {
    const team1Players = queue.slice(0, 5);
    const team2Players = queue.slice(5, 10);
    setTeam1(team1Players);
    setTeam2(team2Players);
    setQueue(queue.slice(10));
  };

  const handleWin = (winningTeam, losingTeam, setLosingTeam) => {
    setQueue([...queue, ...losingTeam]);
    setLosingTeam([]);
    const newTeam = queue.slice(0, 5);
    setQueue(queue.slice(5));
    if (winningTeam === team1) {
      setTeam2(newTeam);
    } else {
      setTeam1(newTeam);
    }
  };

  const addNewPlayer = () => {
    if (newPlayerName.trim() !== '') {
      const newPlayer = { ID: Date.now(), post_title: newPlayerName.trim() };
      axios.post('/wp-json/basketball/v1/add-player', newPlayer)
        .then(response => {
          setAvailablePlayers([...availablePlayers, response.data]);
          setNewPlayerName('');
        })
        .catch(error => {
          console.error('Error adding player:', error);
        });
    }
  };

  return (
    <div className="App">
      <h1>Basketball Team Manager</h1>
      <button onClick={formTeams}>Form Teams</button>

      <div className="teams-container">
        <div className="team">
          <h2>Team 1 <button onClick={() => handleWin(team1, team2, setTeam2)}>Win</button></h2>
          {team1.map(player => (
            <div key={player.ID}>
              {player.post_title}
              <button onClick={() => movePlayerUp(team1, setTeam1, player)}>Move Up</button>
              <button onClick={() => movePlayerDown(team1, setTeam1, player)}>Move Down</button>
              <button onClick={() => addPlayerToQueue(player)}>Sit Out (back to queue)</button>
              <button onClick={() => setTeam2([...team2, player])}>Move to Team 2</button>
            </div>
          ))}
        </div>

        <div className="team">
          <h2>Team 2 <button onClick={() => handleWin(team2, team1, setTeam1)}>Win</button></h2>
          {team2.map(player => (
            <div key={player.ID}>
              {player.post_title}
              <button onClick={() => movePlayerUp(team2, setTeam2, player)}>Move Up</button>
              <button onClick={() => movePlayerDown(team2, setTeam2, player)}>Move Down</button>
              <button onClick={() => addPlayerToQueue(player)}>Sit Out (back to queue)</button>
              <button onClick={() => setTeam1([...team1, player])}>Move to Team 1</button>
            </div>
          ))}
        </div>
      </div>

      <h2>Queue</h2>
      {queue.map(player => (
        <div key={player.ID}>
          {player.post_title}
          <button onClick={() => removePlayerFromQueue(player)}>Remove from Session</button>
          <button onClick={() => movePlayerUp(queue, setQueue, player)}>Move Up</button>
          <button onClick={() => movePlayerDown(queue, setQueue, player)}>Move Down</button>
        </div>
      ))}

      <h2>Available Players</h2>
      {availablePlayers.map(player => (
        <div key={player.ID}>
          {player.post_title}
          <button onClick={() => addPlayerToQueue(player)}>Add to Queue</button>
        </div>
      ))}

      <div className="new-player">
        <input
          type="text"
          value={newPlayerName}
          onChange={(e) => setNewPlayerName(e.target.value)}
          placeholder="Enter new player name"
          className="new-player-input"
        />
        <button onClick={addNewPlayer}>Add New Player</button>
      </div>
    </div>
  );
}

export default App;
